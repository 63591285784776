<template>
  <div class="mgAll10">
    <el-dialog class="custom-dialog" :title="'粮库'+(formType?'编辑':'添加')" :visible.sync="dialogVisible" width="520px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="粮库名称" prop="liangName" class="custom-form-item">
              <el-input v-model="form.liangName" placeholder="请输入粮库名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属公司" prop="companyId" class="custom-form-item">
              <el-select v-model="form.companyId" filterable remote placeholder="请输入关键词" :remote-method="remoteMethod"
                :loading="companyLoading">
                <el-option v-for="(item,index) in companyList" :key="index" :label="item.companyName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contacts" class="custom-form-item">
              <el-input v-model="form.contacts" placeholder="请输入联系人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactNumber" class="custom-form-item">
              <el-input v-model="form.contactNumber" placeholder="请输入联系电话" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="地址选择" v-if="!formType" class="custom-form-item">
          <el-cascader style="width: 100%;" :options="areaOptions" v-model="form.areas">
          </el-cascader>
        </el-form-item>
        <el-form-item label="过期时间" v-if="!formType" class="custom-form-item" prop="expireTime">
          <el-date-picker style="width: 100%;" format="yyyy-MM-dd " v-model="form.expireTime" type="date"
            placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="详细地址" class="custom-form-item">
          <el-input v-model="form.liangAddress" placeholder="请输入详细地址" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <div>
        <!-- 搜索 -->
        <el-input v-model="searchForm.companyName" placeholder="公司名称" class="mgB10 custom-form-input"></el-input>

        <el-input v-model="searchForm.liangName" placeholder="粮库名称" class="mgL10 mgB10 custom-form-input"></el-input>
        <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
        </el-button>
        <el-button type="success" @click="add" plain class="mgL10 mgB10">添加
        </el-button>
        <!-- 表格 -->
        <el-table :data="tableData" border v-loading="loading" row-key="id">
          <el-table-column type="index" header-align="center" align="center" label="序号" width="50"></el-table-column>
          <el-table-column prop="liangName" :show-overflow-tooltip="true" header-align="center" align="center"
            label="粮库名称"></el-table-column>
          <el-table-column prop="contacts" :show-overflow-tooltip="true" header-align="center" align="center"
            label="联系人"></el-table-column>
          <el-table-column prop="contactNumber" :show-overflow-tooltip="true" header-align="center" align="center"
            label="联系电话">
          </el-table-column>
          <el-table-column prop="companyName" :show-overflow-tooltip="true" header-align="center" align="center"
            label="所属公司">
          </el-table-column>
          <el-table-column prop="expireTime" :show-overflow-tooltip="true" header-align="center" align="center"
            label="到期时间">
          </el-table-column>
          <el-table-column prop="liangAddress" :show-overflow-tooltip="true" header-align="center" align="center"
            label="粮库地址">
          </el-table-column>
          <el-table-column label="操作" width="260" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
                编辑
              </el-button>
              <el-button @click.native.prevent="renewRow(scope.row)" type="primary" size="mini"
                class="custom-table-btn">
                续订
              </el-button>
              <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
                删除
              </el-button>
              <el-button @click.native.prevent="logRow(scope.row)" type="success" size="mini" class="custom-table-btn">
                续订记录
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach">
        </table-page>
      </div>
    </el-card>
  </div>
</template>

<script>
  var moment = require('moment');
  import {
    regionData,
    CodeToText
  } from 'element-china-area-data'
  const state = [{
      value: 1,
      name: "启用"
    },
    {
      value: 0,
      name: "停用"
    }
  ]
  export default {
    name: "systemMenu",
    filters: {},
    data() {
      return {
        companyLoading: false,
        companyList: [],
        areaOptions: regionData,
        states: state,
        tableData: [],
        loading: false,
        searchForm: {
          liangName: "",
          companyName: "",
          page: 1,
          limit: 10,
        },
        total: 0,
        dialogVisible: false,
        formType: false,
        loadingAlert: false,
        form: {
          liangName: "",
          companyId: "",
          contacts: "",
          contactNumber: "",
          liangAddress: "",
          expireTime: "",
          areas: []
        },
        rules: {
          liangName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          companyId: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          contacts: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          expireTime: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          contactNumber: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (!this.$util.isPoneAvailable(value)) {
                callback(new Error('请输入正确的手机号码!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }]
        }
      }
    },
    mounted() {
      this.search();
    },
    methods: {
      search() {
        this.getData(1, this.searchForm.limit)
      },
      getData(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.grainLiangListPage, {
          companyName: this.searchForm.companyName,
          liangName: this.searchForm.liangName,
          page: page,
          limit: limit
        }).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      feach(e) {
        this.getData(e.page, e.limit)
      },
      //远程搜索
      remoteMethod(e) {
        if (e) {
          this.companyLoading = true
          this.$axios.Get(this.$api.companyListByName, {
            name: e
          }).then(res => {
            this.companyList = res.data
            this.companyLoading = false
          }).catch(() => {
            this.companyLoading = false
          })
        } else {
          this.companyList = []
        }
      },
      //续订记录
      logRow(e) {
        this.$router.push({
          path: "/logger/renew",
          query: {
            name: e.liangName
          }
        })
      },
      //续订
      renewRow: function(e) {
        this.$prompt('请输入续订到期时间', '粮库续订', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: "date",
          inputValidator: (value) => {
            if (value) {
              return true
            } else {
              return false
            }
          }
        }).then(({
          value
        }) => {
          this.$axios.Post(this.$api.grainLiangRenew, {
            "id": e.id,
            "expireTime": moment(value).format('YYYY-MM-DD')
          }).then(() => {
            this.$message({
              type: 'success',
              message: "续订成功"
            });
            this.getData(this.searchForm.page, this.searchForm.limit)
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});

      },
      editRow(row) {
        this.formType = true;
        this.dialogVisible = true;
        this.form = JSON.parse(JSON.stringify(row))
      },
      add() {
        this.dialogVisible = true;
      },
      //关闭
      closeAlert() {
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.id = "";
        this.loadingAlert = false;
        this.formType = false;
      },
      //表单提交
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.form)
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信息
      addUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        if (params.areas && params.areas.length > 0) {
          params.liangAddress = params.liangAddress + CodeToText[params.areas[0]] + CodeToText[params.areas[1]] +
            CodeToText[params.areas[2]]
        }
        params['expireTime'] = moment(params.expireTime).format('YYYY-MM-DD')
        this.$axios.Post(this.$api.grainLiangAdd, params).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        if (params.areas && params.areas.length > 0) {
          params.liangAddress = params.liangAddress + CodeToText[params.areas[0]] + CodeToText[params.areas[1]] +
            CodeToText[params.areas[2]]
        }
        params['expireTime'] = moment(params.expireTime).format('YYYY-MM-DD')
        this.$axios.Post(this.$api.grainLiangModify, params).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //删除表格数据
      delRow: function(row) {
        this.$confirm('确认要删除该条信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.Post(this.$api.grainLiangDel, {
            "id": row.id
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getData(this.searchForm.page, this.searchForm.limit)
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      }
    },
    components: {
      "table-page": () => import('@/components/TablePage')
    }
  }
</script>

<style lang="less">

</style>
